//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSkeleton from '@/components/LoadingSkeleton.vue'
import GroupResult from '@/components/Groups/GroupResult'
import EmptyMessage from '@/components/EmptyMessage'
export default {
    components:{
        GroupResult,
        EmptyMessage,
        LoadingSkeleton,
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    data() {
        return {
            currentPage: 1,
            maxNumberOfPages: 1,
            user_id: this.$store.state.user.id,
            q: '',
            isEmpty: false,
            isLoading: true,
            groups: [],
            isDark: false,
            searchText: ''
        } 
    },
    metaInfo(){
        return {
            title:'Groups'
        }
    },
    methods: {
        search(){
            this.q = this.searchText
            this.isLoading = true;
            this.getGroups()
        },
        getGroups() {
            this.isLoading = true;
            this.$http({
                url: 'groups?page=' + this.currentPage + '&q=' + this.q,
                method: 'GET'
            }).then((response) => {
                if (response.data['status'] == 'groups_retrieved') {
                    this.isLoading = false;
                    this.groups = response.data['body']['groups'];
                    this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
                    this.isEmpty = false;
                } else if (response.data['status'] == 'no_groups') {
                    this.isLoading = false;
                    this.isEmpty = true;
                }
            })
        }
    },
    created(){
        this.getGroups();
    }
}
