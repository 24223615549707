//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar'
export default {
    name: 'GroupResult',
    components: { Avatar},
    props: ['group'],
    data() {
        return {
            groupCoverPhoto: require('@/assets/default_cover2.webp')
        }
    },
    methods: {
        fetchThumbnails(id){
            this.payload = {
                id:id
            }
            this.$store.dispatch('group/getCoverPhoto',this.payload)
            .then((r) => {
                r.status == 'success' ? this.groupCoverPhoto = r.image : ''
            })
        },
    },
    created() {
            this.fetchThumbnails(this.group.id)
    },
}
