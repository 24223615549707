import { render, staticRenderFns } from "./Groups.vue?vue&type=template&id=f9ca61aa&"
import script from "./Groups.vue?vue&type=script&lang=js&"
export * from "./Groups.vue?vue&type=script&lang=js&"
import style0 from "./Groups.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QInput,QIcon,QPagination,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QInput,QIcon,QPagination})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
